import { Helmet } from 'react-helmet-async';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AWS from 'aws-sdk';
import Tags from './Tags';
import LinearProgress from '@mui/material/LinearProgress';
import '../Timelineposts/timeline.css';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Modal,
  RadioGroup,
  Radio,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
  Alert,
  CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import axios from 'axios';
import Posts from './Posts';
import Fade from 'react-reveal/Fade';
import LoaderAnimation from 'src/components/LoaderAnimation/LoaderAnimation';
import Footer from 'src/components/Footer';
// import InputEmoji from 'react-input-emoji';
// import Picker from 'emoji-picker-react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

//storing image in bucket
AWS.config.update({
  accessKeyId: 'AKIAWUZTVEDMASCEIVA5',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
  signatureVersion: 'v4'
});

// interface TimelinepostsProps {
//   setPostCount: (count: number) => void;
// }
// : React.FC<TimelinepostsProps> 
const Timelineposts= () => {
  const userdetail = JSON.parse(localStorage.getItem('userdetails'));
  const username = userdetail?.username;
  const USERID = localStorage.getItem('USER_ID');
  const [progress, setProgress] = useState(0);
  const [state, setState] = useState();
  const [myposts, setMyPosts] = useState([]);
  const [LoadingPosts, setLoadingPosts] = useState(true);
  const [ErrorPosts, setErrorPosts] = useState(false);
  const [isPostLoading, setisPostLoading] = useState(false);
  const [textError, setTextError] = useState(false);
  // const [MediaError, setMediaError] = useState(false);
  const [PostAudienceError, setPostAudienceError] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [tagshow, setTagsShow] = useState(false);
  const [image, setImage] = useState(null);
  const [inputStr, setInputStr] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const s3 = new AWS.S3();

  // listing for timeline post
  const sortedDesc = myposts.sort((a: any, b: any) => {
    return +new Date(b.created_at) - +new Date(a.created_at);
  });

  const onEmojiClick = (emojiObject, event: MouseEvent) => {
    // console.log('emojiObject', emojiObject);
    setInputStr((prevInput) => prevInput + emojiObject.native);
    setShowPicker(false);
  };

  const [obj, setObj] = useState({
    userId: `${USERID}`,
    postType: '',
    media: '',
    mediaType: '',
    accessibility: '',
    description: '',
    tags: []
  });

  useEffect(() => {
    setObj({ ...obj, ['tags']: state });
  }, [state]);

  useEffect(() => {
    setObj({ ...obj, ['description']: inputStr });
  }, [inputStr]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setObj({
      ...obj,
      media: '',
      mediaType: '',
      postType: '',
      accessibility: '',
      description: ''
    });
    setImage('');
    setPostAudienceError(false);
    setTextError(false);
    // setMediaError(false);
    setOpen(false);
  };

  // listing for timeline post
  const getAllPosts = async () => {
    try {
      setLoadingPosts(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/post/getallpost/${USERID}`
      );
      const data = response?.data;
      setMyPosts(data);
      setLoadingPosts(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  const handleFileSelect = async (e) => {
    try {
      setLoader(true);
      setImage(e?.target?.files[0]?.name);
      console.log('EVENTTT', e?.target?.files[0]);

      const params = {
        Bucket: 'clwbuckets',
        Key: `${Date.now()}.${e?.target?.files[0]?.name}`,
        Body: e?.target?.files[0],
        ACL: 'public-read'
      };
      const { Location } = await s3.upload(params).promise();
      setObj((prevState) => ({
        ...prevState,
        media: Location,
        mediaType: e.target.files[0].type
      }));
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange1 = (e) => {
    if (e && e.target) {
      let { name, value, checked, type } = e.target;
      value = type === 'checkbox' ? checked : value;
      setObj({ ...obj, [name]: value });
    }
  };

  useEffect(() => {
    if (obj?.accessibility) {
      setPostAudienceError(false);
    }
    if (obj?.description) {
      setTextError(false);
    }
  }, [obj?.accessibility, obj?.description]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (obj.accessibility === '') {
        setPostAudienceError(true);
        return;
      }
      if (obj.description === '') {
        setTextError(true);
        return;
      }
      setisPostLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_HOME_URL}/post/add`,
        obj
      );
      console.log("response",response?.data?.data)

      // setPostCount(response?.data?.data?.totalPost);
      toast.success('Post successfully uploaded');
      setisPostLoading(false);
      setObj({ 
        ...obj,
        media: '',
        mediaType: '',
        accessibility: '',
        description: ''
      });
      setImage('');
      handleClose();
      getAllPosts();
    } catch (err) {
      console.log(err);
    }
  };

  const pinnedPosts = myposts.filter((post) => post?.pinStatus);
  const nonPinnedPosts = myposts.filter((post) => !post?.pinStatus);
  const sortedNonPinnedPosts = nonPinnedPosts.sort((a, b) => {
    return +new Date(b.created_at) - +new Date(a.created_at);
  });
  const sortedPosts = [...pinnedPosts, ...sortedNonPinnedPosts];

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <div className="page-title only-myposts">
        <p
          className="russoFont afterBorder"
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            fontSize: '26px',
            textTransform: 'capitalize',
            color: '#27AA58',
            margin: '0 0'
          }}
        >
          My Posts
        </p>
      </div>

      <div className="timeline-wrapper">
        <Container
          maxWidth="lg"
          style={{
            paddingRight: '14px',
            paddingLeft: '0'
          }}
        >
          <Box
            className="btn-bg-value"
            maxWidth="lg"
            sx={{
              backgroundColor: '#EDFFE5',
              border: '1px solid rgb(147, 251, 66)',
              borderRadius: '14px',
              height: 'auto',
              padding: '10px 20px'
            }}
          >
            <Grid
              className="header-bg-box"
              container
              spacing={2}
              style={{ alignItems: 'center', marginTop: '0' }}
            >
              <Grid className="pt-0" item pl={2} xs={6}>
                <Typography
                  className="russoFont"
                  variant="h3"
                  style={{
                    color: '#27AA58',
                    fontSize: '14px',
                    animation: 'typing infinite',
                    animationDuration: '1.5s',
                    animationTimingFunction: 'steps(30, end)',
                    animationFillMode: 'forwards',
                    width: '0',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    cursor: 'pointer'
                  }}
                >
                  WRITE A POST...
                </Typography>
              </Grid>

              <Grid sx={{ textAlign: 'end' }} className="pt-0" item xs={6}>
                {' '}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Write a Post
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Container
          maxWidth="lg"
          style={{
            padding: '16px 18px 25px 0'
          }}
        >
          {LoadingPosts && (
            <Box
              sx={{
                height: '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#EDFFE5',
                border: '1px solid rgb(147, 251, 66)',
                borderRadius: '14px'
              }}
            >
              <LoaderAnimation />
            </Box>
          )}
          {ErrorPosts && (
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'center',
                backgroundColor: '#EDFFE5',
                borderColor: '#93FB42',
                borderRadius: '14px'
              }}
            >
              <Alert severity="error">This is an error Occured</Alert>
            </Box>
          )}

          {sortedPosts.map((data) => (
            <Fade bottom key={data?._id}>
              <Posts data={data} key={data?._id} getAllPosts={getAllPosts} />
            </Fade>
          ))}
        </Container>

        {/* create post  */}
        <div>
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              className={'selectCatbox'}
              style={{ overflow: 'scroll' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{ mt: 2, mb: 2 }}
                  id="modal-modal-title"
                  variant="h3"
                  component="h2"
                >
                  Select the Category for your Posts
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseRoundedIcon fontSize="large" />
                </IconButton>
              </div>

              <FormControl className="mb-3">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="postType"
                  value={obj.postType}
                  onClick={handleChange1}
                >
                  <FormControlLabel
                    value="Normal_daily_post"
                    control={<Radio />}
                    label="Normal/ Daily Posts"
                  />
                  <FormControlLabel
                    value="Good_deed_post"
                    control={<Radio />}
                    label="Good Deeds Posts"
                  />
                  <FormControlLabel
                    value="Memorable_event_post"
                    control={<Radio />}
                    label="Memorable / Event Posts"
                  />
                </RadioGroup>
              </FormControl>
              {obj.postType === 'Normal_daily_post' ||
              obj.postType === 'Good_deed_post' ||
              obj.postType === 'Memorable_event_post' ? (
                <>
                  {' '}
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14, mb: 1 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Create A{' '}
                        {obj.postType === 'Normal_daily_post'
                          ? 'Normal Daily Post'
                          : obj.postType === 'Good_deed_post'
                          ? 'Good Deed Post'
                          : 'Memorable Event Post'}
                      </Typography>
                      <form className="add-image-profile">
                        <div className={'avatarProfileSection'}>
                          <Typography
                            sx={{
                              width: '50px',
                              height: '50px',
                              mr: '-5px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: '1px solid gray',
                              borderRadius: '50%',
                              fontSize: '1.2rem',
                              background: '#0d5400',
                              color: '#fff'
                            }}
                          >
                            {username?.[0]}
                          </Typography>
                          <p>{username}</p>
                        </div>

                        <FormControl
                          className="postaudfullwidth"
                          variant="standard"
                          fullWidth
                          sx={{ mb: 2, minWidth: 120 }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Post Audience
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={obj.accessibility}
                            name="accessibility"
                            onChange={handleChange1}
                          >
                            <MenuItem value={'public'}>Public</MenuItem>
                            <MenuItem value={'tier_1_group'}>
                              Tier 1 group
                            </MenuItem>
                            <MenuItem value={'tier_2_group'}>
                              Tier 2 group
                            </MenuItem>
                            <MenuItem value={'Both_groups'}>
                              Both groups
                            </MenuItem>
                            <MenuItem value={'Only_me'}>Only me</MenuItem>
                          </Select>
                        </FormControl>
                        {PostAudienceError && (
                          <small style={{color:'red', margin:'5px', marginBottom:'8px'}}>
                            This is a required field.
                          </small>
                        )}
                        <br />

                        <FormControl fullWidth sx={{ mb: 2, minWidth: 120 }}>
                          {/* <InputEmoji
                            name="description"
                            value={obj.description}
                            onChange={descriChange}
                            className={'design'}
                            placeholder="Type a message"
                          /> */}

                          <div className="picker-container">
                            <textarea
                              className="input-style"
                              name="description"
                              value={obj?.description}
                              // onChange={handleDescription}
                              onChange={(e) => setInputStr(e.target.value)}
                            />
                            <img
                              className="emoji-icon"
                              src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                              onClick={() => setShowPicker((val) => !val)}
                            />
                          </div>
                        </FormControl>

                        {showPicker && (
                          <Picker data={data} onEmojiSelect={onEmojiClick} />
                        )}

                        {/* {showPicker && <Picker onEmojiClick={onEmojiClick} />} */}
                      </form>

                      {textError && (
                       <small style={{color:'red', margin:'5px'}}>
                       This is a required field.
                     </small>
                      )}

                      <div className="file-input">
                        <input
                          type="file"
                          name="file"
                          onChange={handleFileSelect}
                          accept=".jpg ,.png ,.jpeg ,audio/mpeg,audio/mp3 ,video/*"
                        />
                        <span className="button">Upload</span>
                      </div>

                      {Loader ? (
                        <Box sx={{ width: '100%', mt: 2 }}>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                          />
                        </Box>
                      ) : (
                        <Box sx={{ m: 1 }}>
                          <Typography sx={{ fontSize: 12 }}>{image}</Typography>
                        </Box>
                      )}

                      {/* {MediaError && (
                        <div className="invalid-data">Please upload Image.</div>
                      )} */}

                      <FormControl
                        sx={{
                          m: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignContent: 'center'
                        }}
                      />

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignContent: 'center'
                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                justifyContent: 'space-between',
                                display: 'flex'
                              }}
                            >
                              <Button
                                sx={{ borderRadius: '2px', height: 40 }}
                                color="success"
                                variant="contained"
                                onClick={() => setTagsShow(!tagshow)}
                              >
                                Tags
                              </Button>
                              <Tags tagshow={tagshow} setState={setState} />
                            </Box>
                          </Grid>
                        
                        </Grid>
                      </div>
                    </CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CardActions>
                        <Button
                          sx={{ minWidth: 127 }}
                          className="postbutton"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          disabled={isPostLoading}
                        >
                          {isPostLoading ? (
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress size="1.5rem" color="inherit" />
                            </Box>
                          ) : (
                            'POST'
                          )}
                        </Button>
                      </CardActions>
                    </Box>
                  </Card>
                </>
              ) : null}
            </Box>
          </Modal>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Timelineposts;
