import * as React from 'react';
import './Login.css';
import Button from '@mui/material/Button';
import MailIcon from '@mui/icons-material/Mail';
import LockIcon from '@mui/icons-material/Lock';
import {
  Avatar,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Container,
  CssBaseline,
  CircularProgress,
  Box
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from '../Footer';
import Header from '../Header/Header';
import { useTranslation } from 'react-i18next';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoginLoading, setisLoginLoading] = React.useState(false);
  const [UnameOrEmail, setUnameOrEmail] = React.useState('');
  const [password, setpassword] = React.useState('');
  const [invalidCred, setInvalidCred] = React.useState('');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidUser, setInvalidUser] = React.useState(false);
  const [invalidPass, setInvalidPass] = React.useState(false);
  const [loggedIn, setloggedIn] = React.useState(false);

  React.useEffect(() => {
    if (UnameOrEmail) {
      setInvalidEmail(false);
    }
    if (password) {
      setInvalidPass(false);
    }
    if (UnameOrEmail.length > 2) {
      setInvalidUser(false);
    }
  }, [UnameOrEmail, password]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      username: '',
      email: '',
      password: password
    };
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(UnameOrEmail)) {
      data.email = UnameOrEmail;
    } else {
      data.username = UnameOrEmail;
    }

    if (UnameOrEmail !== '' && UnameOrEmail.length < 3) {
      setInvalidUser(true);
      return;
    }
    if (UnameOrEmail === '') {
      setInvalidEmail(true);
      return;
    } else if (password === '') {
      setInvalidPass(true);
      return;
    }
    setisLoginLoading(true);
    axios.post(`${process.env.REACT_APP_HOME_URL}/user/login`, data)
      .then((mydata) => {
        // console.log('LOGIN', mydata);
        if (mydata?.data?.data) {
          setloggedIn(true);
        }
        setisLoginLoading(false);
        localStorage.setItem('userdetails', JSON.stringify(mydata?.data?.data));
        localStorage.setItem('USER_ID', mydata?.data?.data?._id);
        navigate('/dashboards/main');
      })
      .catch((err) => {
        setisLoginLoading(false);
        setInvalidCred(err?.response?.data?.message);
      });
  };
  React.useEffect(() => {
    localStorage.setItem('IsloggedIn', JSON.stringify(loggedIn));
  }, [loggedIn]);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>CLW-Login</title>
      </Helmet>
      <CssBaseline />

      <div className="mainBg login">
        <Header />

        <form action="" onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Container maxWidth="sm" className="mainBox">
            <div className="mainBoxInnerWrapper layOutSize">
              <div
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  margin: 'auto'
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&amp;token=c8928674-602c-4edf-9058-7941fa74fcdd"
                  className="Apple-logo1"
                />
                <h2>{t('loginTitle')}</h2>
              </div>
              <div className="emailWrapper">
                <div className="inner">
                  <TextField
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    type="text"
                    placeholder={t('email')}
                    variant="outlined"
                    value={UnameOrEmail}
                    onChange={(e) => setUnameOrEmail(e.target.value)}
                    // {...register('email')}
                  />

                  {invalidEmail && (
                    <div className="invalid-data">
                      This is a required field.
                    </div>
                  )}
                  {invalidUser && (
                    <div className="invalid-data">
                      Please enter a valid Username / Email.
                    </div>
                  )}
                </div>
              </div>
              <div className="emailWrapper">
                <div className="inner">
                  <FormControl sx={{ width: '100%' }} variant="outlined">
                    {/* <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel> */}
                    <OutlinedInput id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            className="eye-icon"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            color="primary"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      placeholder={t('Pass')}
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </FormControl>

                  {invalidPass && (
                    <div className="invalid-data">
                      This is a required field.
                    </div>
                  )}
                  <small style={{ color: 'red' }}>{invalidCred}</small>
                  <Button
                    onClick={() => navigate('/forgotpassword')}
                    className="forgetBtn"
                  >
                    {' '}
                    {t('ForgotP')}
                    {/* Forgot Password? */}
                  </Button>
                </div>
              </div>

              <div className="inner">
                <Button
                  type="submit"
                  sx={{ mt: 1, mb: 2 }}
                  variant="contained"
                  disabled={isLoginLoading}
                  style={{
                    margin: 'auto',
                    width: '100%',
                    backgroundColor: '#27AA58',
                    color: '#ffffff',
                    border: '1px solid  rgb(147, 251, 66)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px'
                  }}
                >
                  {isLoginLoading ? (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress size="1.5rem" color="inherit" />
                    </Box>
                  ) : (
                    t('loginButton')
                  )}
                  {/* Login */}
                </Button>
              </div>

              <br />
              <div className="inner">
                <Button
                  onClick={() => navigate('/register')}
                  sx={{ mt: 1, mb: 2 }}
                  variant="contained"
                  style={{
                    margin: 'auto',
                    width: '100%',
                    backgroundColor: '#ffffff',
                    color: '#27AA58',
                    border: '1px solid  rgb(147, 251, 66)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px'
                  }}
                >
                  {t('signupButton')}
                  {/* Sign up */}
                </Button>
              </div>
            </div>
          </Container>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Login;
