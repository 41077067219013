import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  TextField
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import './ForgotPassword.css';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header/Header';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [isForgotPLoading, setisForgotPLoading] = React.useState(false);
  const [handleEmail, setHandleEmail] = React.useState(false);
  const [InvalidEmail, setInvalidEmail] = React.useState(false);
  const [handleErr, setHandleErr] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      setHandleEmail(true);
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setHandleEmail(false);
      setInvalidEmail(true);
      return;
    } else {
      setisForgotPLoading(true);
      axios
        .post(`${process.env.REACT_APP_HOME_URL}/user/forgetpassword`, {
          email
        })
        .then((mydata) => {
          setisForgotPLoading(false);
          navigate('/sentemail');
        })
        .catch((err) => {
          setisForgotPLoading(false);
          setHandleErr(err?.response?.data);
        });
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="mainBg login">
        <Header />

        <form action="" className="forgot-form" onSubmit={handleSubmit}>
          <Container maxWidth="sm" className="mainBox">
            <div className="mainBoxInnerWrapper layOutSize">
              <div
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  margin: 'auto'
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&amp;token=c8928674-602c-4edf-9058-7941fa74fcdd"
                  className="Apple-logo"
                />
                <h2>Forget Password?</h2>
              </div>
              <div className="emailWrapper fPass">
                <div className="inner">
                  <TextField
                    sx={{ m: 1 }}
                    id="email"
                    type="email"
                    placeholder="Enter your Email"
                    variant="outlined"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {handleEmail && (
                  <div className="invalid-data">Required field.</div>
                )}
                {InvalidEmail && (
                  <div className="invalid-data">
                    Please enter a valid email id.
                  </div>
                )}
                {handleErr && (
                  <div className="invalid-data">User not found.</div>
                )}
              </div>
              <div className="inner">
                <Button
                  type="submit"
                  className="login-button"
                  sx={{ m: 1 }}
                  variant="contained"
                  disabled={isForgotPLoading}
                  style={{
                    margin: 'auto',
                    width: '100%',
                    backgroundColor: '#27AA58',
                    color: '#ffffff',
                    border: '1px solid  rgb(147, 251, 66)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px',
                    fontWeight: 400,
                    minHeight: '50px'
                  }}
                >
                  {isForgotPLoading ? (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress size="1.5rem" color="inherit" />
                    </Box>
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </div>
              <br />
              <div className="inner">
                <Button
                  onClick={() => navigate('/login')}
                  className="login-button"
                  sx={{ m: 1 }}
                  style={{
                    margin: 'auto',
                    width: '100%',
                    backgroundColor: '#ffffff',
                    color: '#27AA58',
                    border: '1px solid  rgb(147, 251, 66)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'capitalize',
                    padding: '12px'
                  }}
                  variant="contained"
                >
                  LOGIN
                </Button>
              </div>
            </div>
          </Container>
        </form>
      </div>
      <Footer />
    </>
  );
};
