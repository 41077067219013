import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import Web3 from 'web3';
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  Modal,
  CircularProgress
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useParams } from 'react-router';
import axios from 'axios';
import './NFTS-details.css';
import ABI from './ABI.json';
import LegacyABI from './LegacyABI.json';
import { ToastContainer, toast } from 'react-toastify';
import { useAddress } from '@thirdweb-dev/react';
import nftlevel from './nft.json';
import NFTAttributes from './NFt-Attributes/NFTAttributes';
import LoaderAnimation from 'src/components/LoaderAnimation/LoaderAnimation';
import { OpenSeaSDK, Chain } from 'opensea-js';
declare var window: any;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  p: 4,
  border: '1px solid #27AA58',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderradius: '16px'
};

const NFTDetails = () => {
  let provider = window.ethereum as any;
  const address = useAddress();
  const navigate = useNavigate();
  const [isH2OLoading, setH2OLoading] = useState(false);
  const [islevelLoading, setislevelLoading] = useState(false);
  const [issaleLoading, setissaleLoading] = useState(false);
  const [isRenameLoading, setisRenameLoading] = useState(false);
  const [isStakeLoading, setisStakeLoading] = useState(false);
  const [dataLoader, setdataLoader] = useState(false);
  const [NFTDetailsByToken, setNFTDetailsByToken] = useState(null);
  const [NftName, setNftName] = useState(null);
  const [nftCurrentlevel, setnftCurrentlevel] = useState(null);
  const [nftHeight, setnftHeight] = useState(null);
  const [nftId, setNftId] = useState(null);
  const [h2OAmount, seth2OAmount] = useState(null);
  const { contract, identifier } = useParams();
  const [giveh20input, setGiveH20Input] = useState(0);
  const [saleInput, setSaleInput] = useState(0);
  const [handleH20InputErr, sethandleH20InputErr] = useState(false);
  const [handleRenameErr, sethandleRenameErr] = useState(false);
  const [opens, setOpens] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSetForSale, setOpenSetForSale] = useState(false);
  const handleClose = () => setOpens(false);
  const handleCloses = () => {
    setNewName('');
    sethandleRenameErr(false);
    setisRenameLoading(false);
    setOpen(false);
  };
  const [newName, setNewName] = useState('');
  const [stakeNft, setStakeNft] = useState(null);
  const [AlreadyForSale, setAlreadyForSale] = useState(false);

  const goBack = () => {
    navigate('/management/nfts');
  };

  const nftDetailByToken = async () => {
    try {
      setdataLoader(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/ByToken/${identifier}`
      );
      const data = response?.data?.data;
      // console.log('RESPONSEOFNFTByToken', response);
      setNFTDetailsByToken(data);
      setNftName(data?.name);
      setnftCurrentlevel(data?.currentLevel);
      setnftHeight(data?.height);
      seth2OAmount(data?.h2o);
      setNftId(data?._id);
      setStakeNft(data?.staked);
      setAlreadyForSale(data?.setforsale);
      setdataLoader(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  useEffect(() => {
    nftDetailByToken();
  }, []);

  useEffect(() => {
    if (giveh20input !== 0) {
      sethandleH20InputErr(false);
    }
    if (saleInput !== 0) {
      sethandleSaleInput(false);
    }
  }, [giveh20input, saleInput]);

  // --------------------LOGIC for Transfer ------------------------

  const transferTokens = async (
    senderAddress,
    recipientAddress,
    amount,
    tokenContractAddress,
    tokenContractABI,
    flag
  ) => {
    try {
      if (flag === 'h20') {
        setH2OLoading(true);
        sethandleH20InputErr(false);
      }
      if (flag === 'levelup') setislevelLoading(true);
      if (flag === 'legacy') setisRenameLoading(true);
      if (flag === 'unstake' || flag === 'stake') setisStakeLoading(true);

      // Check if MetaMask is installed and enabled
      if ((typeof window.ethereum as any) === 'undefined') {
        return;
      }
      // Connect to MetaMask
      await provider.request({ method: 'eth_requestAccounts' });
      const web3 = new Web3(window.ethereum as any);

      // Load the token contract
      const tokenContract = new web3.eth.Contract(
        tokenContractABI,
        tokenContractAddress
      );

      //count token logic
      const res = await tokenContract.methods.balanceOf(senderAddress).call();
      const tokenAmount = +web3.utils.fromWei(res);
      console.log('tokenAmount', tokenAmount);

      if (flag === 'h20' && +giveh20input > tokenAmount) {
        setH2OLoading(false);
        handleClose();
        toast.error("You don't have enough Water tokens, please claim first.", {
          position: 'bottom-right',
          theme: 'dark'
        });
        return;
      } else if (flag === 'legacy' && tokenAmount < 200) {
        handleCloses();
        toast.error("You don't have enough CLW tokens, please claim first.", {
          position: 'bottom-right',
          theme: 'dark'
        });
      } else {
        //  Estimate the gas required for the transaction

        const gas = await tokenContract.methods
          .transfer(recipientAddress, amount)
          .estimateGas({ from: senderAddress });
        console.log(gas);
        const gasHex = web3.utils.toHex(gas);
        // const gasHex = web3.utils.toHex(gas * 100);
        const transactionObject = {
          from: senderAddress,
          to: tokenContractAddress,
          gas: gasHex,
          data: tokenContract.methods
            .transfer(recipientAddress, amount)
            .encodeABI()
        };
        // Send the transaction using MetaMask for signing
        try {
          const result = await provider.request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: senderAddress,
                to: tokenContractAddress,
                gas: gasHex,
                data: transactionObject.data
              }
            ]
          });
          console.log('Transaction successful. Transaction hash:', result);

          if (flag === 'h20') {
            try {
              const totalH2OAmount = +giveh20input + h2OAmount;
              axios
                .patch(
                  `${process.env.REACT_APP_HOME_URL}/nft/update/${nftId}`,
                  {
                    h2o: totalH2OAmount
                  }
                )
                .then((res) => {
                  // console.log(res);
                  seth2OAmount(res?.data?.data?.h2o);
                  try {
                    axios
                      .post(
                        `${process.env.REACT_APP_HOME_URL}/transaction/add`,
                        {
                          transaction: result,
                          type: 'Give_Water',
                          userId: userId,
                          tokenType: 'WATER',
                          amount: giveh20input,
                          nftId: nftId
                        }
                      )
                      .then((res) => {
                        // console.log(res);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  } catch (error) {
                    console.log(error);
                  }
                });
            } catch (err) {
              console.log(err);
            }
            setH2OLoading(false);
            setGiveH20Input(0);
            handleClose();
            toast.success('You have successfully watered the tree.', {
              position: 'bottom-right',
              theme: 'dark'
            });
          } else if (flag === 'unstake') {
            try {
              const response = await axios.patch(
                `${process.env.REACT_APP_HOME_URL}/nft/update/${nftId}`,
                {
                  staked: false
                }
              );
              console.log('unstaked', response);
              setisStakeLoading(false);
              setStakeNft(false);
              toast.success("You've successfully unstaked your NFT.", {
                position: 'bottom-right',
                theme: 'dark'
              });
            } catch (error) {
              console.error('Error updating data:', error.message);
              // Handle the error here
            }
          } else if (flag === 'stake') {
            try {
              const response = await axios.patch(
                `${process.env.REACT_APP_HOME_URL}/nft/update/${nftId}`,
                {
                  staked: true
                }
              );
              console.log('staked', response);
              setStakeNft(true);
              setisStakeLoading(false);
              toast.success("You've successfully staked your NFT.", {
                position: 'bottom-right',
                theme: 'dark'
              });
            } catch (error) {
              console.error('Error updating data:', error.message);
            }
          } else if (flag === 'levelup') {
            try {
              const remainingH2o = h2OAmount - nftlevel[nftCurrentlevel];
              const response = await axios.patch(
                `${process.env.REACT_APP_HOME_URL}/nft/update/${nftId}`,
                {
                  currentLevel: nftCurrentlevel + 1,
                  height: nftCurrentlevel + 1,
                  h2o: remainingH2o
                }
              );
              // console.log('levelup', response);
              const data = response?.data?.data;
              seth2OAmount(data?.h2o);
              setnftCurrentlevel(data?.currentLevel);
              setnftHeight(data?.height);
              setislevelLoading(false);
              toast.success('Successfully Level Up', {
                position: 'bottom-right',
                theme: 'dark'
              });
            } catch (error) {
              console.error('Error updating data:', error.message);
            }
          } else {
            try {
              try {
                const response1 = await axios.patch(
                  `${process.env.REACT_APP_HOME_URL}/nft/update/${nftId}`,
                  {
                    name: newName
                  }
                );
                // console.log( response);
                const data = response1?.data?.data;
                handleCloses();
                toast.success('NFT renamed successfully.', {
                  position: 'bottom-right',
                  theme: 'dark'
                });
                setNftName(data?.name);

                try {
                  const response2 = await axios.post(
                    `${process.env.REACT_APP_HOME_URL}/transaction/add`,
                    {
                      transaction: result,
                      type: 'Rename_NFT',
                      userId: userId,
                      tokenType: 'CLW',
                      amount: 200
                    }
                  );

                  setisRenameLoading(false);
                  // console.log('trans successfully:', response2);
                } catch (err) {
                  console.log(err);
                }
              } catch (err) {
                console.log(err);
              }
            } catch (err) {
              console.log(err);
            }
          }
        } catch (error) {
          setH2OLoading(false);
          setislevelLoading(false);
          setissaleLoading(false);
          setisRenameLoading(false);
          setisStakeLoading(false);
        }
      }
    } catch (error) {
      console.error('An error occurred while transferring tokens:', error);
      handleCloses(); //rename model
      handleClose(); //h20 modal
      setOpenSetForSale(false); //setforsale modal
      toast.error('Network is busy. Please connect after few minutes.', {
        position: 'bottom-right',
        theme: 'dark'
      });
    }
  };

  const userDetails = JSON.parse(localStorage.getItem('userdetails'));
  const [givewatervalid, setgivewatervalid] = useState(false);
  const userWallet = userDetails?.walletAddress;
  const userId = localStorage.getItem('USER_ID');
  const senderAddress = address;
  const recipientAddress = process.env.REACT_APP_OWNER_ADDRESS;
  const amount = (+giveh20input * Math.pow(10, 18)).toString();
  // console.log("amount",amount)
  const tokenContractAddress = process.env.REACT_APP_H2O_TOKEN_ADDRESS;
  const tokenContractABI = ABI;
  const legacyAmount = (2 * Math.pow(10, 20)).toString();
  const LegacyContractAddress = process.env.REACT_APP_LEGACY_TOKEN_ADDRESS;
  const LegacyContractABI = LegacyABI;
  let flag = '';

  //water the tree logic
  const call = () => {
    if (!giveh20input) {
      sethandleH20InputErr(true);
      return;
    }
    if (giveh20input == 0) {
      setgivewatervalid(true);
      return;
    }
    transferTokens(
      senderAddress,
      recipientAddress,
      amount,
      tokenContractAddress,
      tokenContractABI,
      'h20'
    );
  };
  const handleWaterLogic = () => {
    if (userWallet !== address?.toLocaleLowerCase()) {
      toast.error(
        'Please reconnect again with the wallet address you registered before.',
        {
          position: 'bottom-right',
          theme: 'dark'
        }
      );
      return;
    } else {
      setOpens(true);
    }
  };

  //Rename logic
  const handleRename = async () => {
    if (newName == '') {
      sethandleRenameErr(true);
      return;
    }
    transferTokens(
      senderAddress,
      recipientAddress,
      legacyAmount,
      LegacyContractAddress,
      LegacyContractABI,
      'legacy'
    );
  };

  const handleRenameLogic = () => {
    if (userWallet !== address?.toLocaleLowerCase()) {
      toast.error(
        'Please reconnect again with the wallet address you registered before.',
        {
          position: 'bottom-right',
          theme: 'dark'
        }
      );
      return;
    } else {
      setOpen(true);
    }
  };

  //levelup logic
  const levelUp = () => {
    if (userWallet !== address?.toLocaleLowerCase()) {
      toast.error(
        'Please reconnect again with the wallet address you registered before.',
        {
          position: 'bottom-right',
          theme: 'dark'
        }
      );
      return;
    }
    if (h2OAmount >= nftlevel[nftCurrentlevel]) {
      const amount = 0;
      transferTokens(
        senderAddress,
        recipientAddress,
        amount,
        tokenContractAddress,
        tokenContractABI,
        'levelup'
      );
    } else {
      toast.error('More water required to level up.', {
        position: 'bottom-right',
        theme: 'dark'
      });
    }
  };

  // stake/unstake
  const stakingFunction = () => {
    if (userWallet !== address?.toLocaleLowerCase()) {
      toast.error(
        'Please reconnect again with the wallet address you registered before.',
        {
          position: 'bottom-right',
          theme: 'dark'
        }
      );
      return;
    }
    const amount = 0;
    transferTokens(
      senderAddress,
      recipientAddress,
      amount,
      tokenContractAddress,
      tokenContractABI,
      stakeNft === true ? 'unstake' : 'stake'
    );
  };

  //set for sale logic
  const [handleSaleInput, sethandleSaleInput] = useState(false);

  const handleNFTForSale = () => {
    if (userWallet !== address?.toLocaleLowerCase()) {
      toast.error(
        'Please reconnect again with the wallet address you registered before.',
        {
          position: 'bottom-right',
          theme: 'dark'
        }
      );
      return;
    }
    if (AlreadyForSale === true) {
      toast.success('This NFT has already been set for sale.', {
        position: 'bottom-right',
        theme: 'dark'
      });
      return;
    } else {
      setOpenSetForSale(true);
    }
  };

  const handleSetForSale = async () => {
    try {
      if (saleInput == 0) {
        sethandleSaleInput(true);
        return;
      }

      setissaleLoading(true);
      //create sell order
      const tokenAddress = contract;
      const API_KEY = process.env.REACT_APP_API_KEY;
      const accountAddress = address.toLocaleLowerCase();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const seaport = new OpenSeaSDK(provider, {
        chain: Chain.Polygon,
        apiKey: API_KEY
      });
      console.log("seaport",seaport)
      const signer = provider.getSigner();
      const balance = await signer.getBalance();
      const balanceInEther = ethers.utils.formatEther(balance);
      if (parseFloat(saleInput.toString()) > parseFloat(balanceInEther)) {
        setSaleInput(0);
        setissaleLoading(false);
        setOpenSetForSale(false);
        toast.error('Insufficient balance', {
          position: 'bottom-right',
          theme: 'dark'
        });
        return;
      }
        // const expirationTime = Math.round(Date.now() / 1000 + 60 * 60 * 24); //for 24 hrs
        const expirationTime = Math.round(Date.now() / 1000 + 60 * 60 * 24 * 3); // for 3 days

        try {
          const order = await seaport.createSellOrder({
            asset: {
              tokenId:identifier,
              tokenAddress: tokenAddress
            },
            startAmount: saleInput,
            expirationTime,
            accountAddress: accountAddress
          });
          console.log('order', order);

          try {
            axios
              .post(`${process.env.REACT_APP_HOME_URL}/transaction/add`, {
                transaction: order?.orderHash,
                type: 'SetForSale_NFT',
                userId: userId,
                tokenType: 'SetForSale_NFT',
                amount: saleInput
              })
              .then((res) => {
                console.log(res);

                try {
                  axios
                    .patch(
                      `${process.env.REACT_APP_HOME_URL}/nft/update/${nftId}`,
                      {
                        setforsale: true,
                        price: saleInput
                      }
                    )
                    .then((res) => {
                      setissaleLoading(false);
                      setOpenSetForSale(false);
                      toast.success('Your Nft has been set for sale.', {
                        position: 'bottom-right',
                        theme: 'dark'
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } catch (err) {
                  console.log(err);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } catch (err) {
            console.log(err);
          }
        } catch (error) {
          setissaleLoading(false);
          setOpenSetForSale(false);
          console.log("error",error)
          toast.error('You cannot list this nft at this moment.', {
            position: 'bottom-right',
            theme: 'dark'
          });
        }
    } catch (error) {
      setissaleLoading(false);
      setOpenSetForSale(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>NFT's Details - CLW</title>
      </Helmet>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        limit={6}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div
        style={{
          backgroundImage: 'url(/static/images/tree-bg.png)',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh'
        }}
      >
        <Button sx={{ ml: 2 }} onClick={goBack} className="goback-btn">
          <ArrowBackIosIcon fontSize="small" /> Back to NFT(S)
        </Button>

        <div className="page-title">
          <p
            className="russoFont afterBorder"
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              fontSize: '26px',
              textTransform: 'capitalize',
              color: '#27AA58',
              margin: '0'
            }}
          >
            NFT-Detail(S)
          </p>
        </div>

        <Container sx={{ mt: 4 }} className="container-fluid pb-4">
          <div
            style={{
              width: '95%',
              margin: 'auto auto 50px'
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid
                item
                md={5}
                sx={{
                  alignSelf: 'start'
                }}
              >
                {dataLoader ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 400,
                      width: '100%'
                    }}
                  >
                    <LoaderAnimation />
                  </Box>
                ) : (
                  <>
                    <div className="image-box-value">
                      <img
                        style={{
                          maxWidth: '75%',
                          borderRadius: '11px 11px 0px 0px'
                        }}
                        src={NFTDetailsByToken?.image_url}
                        alt=""
                      />
                    </div>
                    <Box
                      className="rank-box-value"
                      sx={{
                        background: '#EDFFE5'
                      }}
                      style={{
                        padding: 4
                      }}
                    >
                      <Box
                        className="drop-image-bg-box"
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button
                          onClick={handleWaterLogic}
                          sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            zIndex: 10
                          }}
                        >
                          {/* <img
                            className="drop-img"
                            src="../../../static/images/drop.png"
                          /> */}
                          <div className="drop__loader">
                            <svg viewBox={`0 0 30 42`}>
                              <path
                                fill="#84DBFF"
                                d="m 13.552735,2.0426703 q 1.5,3.7999997 10,14.9999997 a 12.8,12.8 0 1 1 -20.0000007,0 Q 12.052736,5.84267 13.552735,2.0426703 Z"
                                id="path2"
                                style={{ stroke: '#84DBFF', strokeWidth: 1 }}
                              />
                              <path
                                style={{
                                  opacity: 1,
                                  fillOpacity: 1,
                                  stroke: 'none',
                                  strokeWidth: 0.12159528,
                                  strokeMiterlimit: 4,
                                  strokeDasharray: 'none',
                                  strokeOpacity: 1
                                }}
                                d="M 0,1.3127116e-4 V 38.582162 H 27.10547 V 1.3127116e-4 Z M 13.552735,2.0431 c 1,2.5333332 4.333334,7.5333335 10,15 a 12.8,12.8 0 1 1 -20.000001,0 c 5.6666682,-7.4666665 9.000002,-12.4666668 10.000001,-15 z"
                                id="rect4487"
                              />
                            </svg>
                            <div className="loader">
                              <div className="fillwater"></div>
                            </div>
                          </div>
                          <span className="give-text-name">
                            Give{' '}
                            <img
                              className="giveupimage"
                              src="../../../static/arrow-up.png"
                            />
                          </span>
                        </Button>
                      </Box>
                      <Typography className=" MuiTypography-root rank-text-value">
                        {NftName}
                      </Typography>
                      <Typography className="MuiTypography-root clw-dark-text-value">
                        #Clw88
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '0 2px'
                        }}
                      >
                        <Button
                          onClick={levelUp}
                          className="btn-green level-fill-color"
                          variant="contained"
                          color="primary"
                          disabled={islevelLoading}
                        >
                          {islevelLoading ? (
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress size="1.5rem" color="inherit" />
                            </Box>
                          ) : (
                            'Level Up'
                          )}
                        </Button>

                        <Button
                          className="btn-green"
                          variant="contained"
                          color="success"
                          onClick={handleRenameLogic}
                        >
                          Rename
                        </Button>
                      </Box>

                      {/* -----------Rename logic---------- */}
                      <Modal
                        open={open}
                        className="custom-modal"
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            sx={{ mb: 2 }}
                            id="modal-modal-title"
                            variant="h2"
                            component="h2"
                            textAlign={'center'}
                          >
                            Rename
                          </Typography>

                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <input
                              type="text"
                              placeholder="New Name"
                              style={{
                                border: '1px solid #93FB42',
                                borderRadius: '5px',
                                padding: 10,
                                background: '#fff'
                              }}
                              onChange={(e) => setNewName(e.target.value)}
                            />
                          </Box>
                          {handleRenameErr && (
                            <div
                              style={{
                                // marginLeft: '120px',
                                marginTop: '10px',
                                color: 'red',
                                textAlign: 'center'
                              }}
                            >
                              Required field.
                            </div>
                          )}

                          <Box
                            sx={{
                              p: 2,
                              textAlign: 'center'
                            }}
                          >
                            <Typography
                              sx={{ mt: 2 }}
                              variant="h4"
                              textAlign={'center'}
                            >
                              You will spend 200 CLW Tokens to rename your Land.
                            </Typography>

                            <Button
                              sx={{ mt: 2, p: 2, mr: 2 }}
                              variant="contained"
                              onClick={handleRename}
                              disabled={isRenameLoading}
                            >
                              {isRenameLoading ? (
                                <Box sx={{ display: 'flex' }}>
                                  <CircularProgress
                                    size="1.5rem"
                                    color="inherit"
                                  />
                                </Box>
                              ) : (
                                'Proceed'
                              )}
                            </Button>
                            <Button
                              sx={{
                                mt: 2,
                                p: 2,
                                ml: 2,
                                backgroundColor: '#c21118'
                              }}
                              variant="contained"
                              onClick={handleCloses}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Modal>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '0 2px'
                        }}
                      >
                        <Button
                          onClick={handleNFTForSale}
                          className="btn-green next-level-btn"
                          sx={{
                            borderRadius: '5px 5px 5px 10px',
                            borderWidth: 1,
                            borderColor: '#1EB001, ',
                            color: '000000'
                          }}
                          color="success"
                        >
                          Set for Sale
                        </Button>

                        {/* ---------open set for sale--------------- */}
                        <Modal
                          open={openSetForSale}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <Typography
                              sx={{ mb: 2 }}
                              id="modal-modal-title"
                              variant="h2"
                              component="h2"
                              textAlign={'center'}
                            >
                              Set for sale
                            </Typography>

                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <input
                                type="number"
                                placeholder="Enter amount"
                                style={{
                                  border: '1px solid #93FB42',
                                  borderRadius: '5px',
                                  padding: 10,
                                  background: '#fff'
                                }}
                                onChange={(e: any) =>
                                  setSaleInput(e.target.value)
                                }
                              />
                              <span style={{ paddingLeft: '10px' }}>MATIC</span>
                            </Box>

                            {handleSaleInput && (
                              <div
                                style={{
                                  marginLeft: '90px',
                                  marginTop: '10px'
                                }}
                                className="invalid-data"
                              >
                                Required field.
                              </div>
                            )}

                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 3 }}
                              variant="h4"
                              textAlign={'center'}
                            >
                              8% fee will be charged to the seller, deducted
                              from the polygon matic.
                            </Typography>

                            <div style={{ marginLeft: '100px' }}>
                              <Button
                                sx={{ mt: 4, p: 2 }}
                                variant="contained"
                                onClick={handleSetForSale}
                                disabled={issaleLoading}
                              >
                                {issaleLoading ? (
                                  <Box sx={{ display: 'flex' }}>
                                    <CircularProgress
                                      size="1.5rem"
                                      color="inherit"
                                    />
                                  </Box>
                                ) : (
                                  'Proceed'
                                )}
                              </Button>
                              <Button
                                sx={{
                                  mt: 4,
                                  p: 2,
                                  ml: 2,
                                  backgroundColor: '#c21118'
                                }}
                                variant="contained"
                                onClick={() => {
                                  setSaleInput(0);
                                  setissaleLoading(false);
                                  sethandleSaleInput(false);
                                  setOpenSetForSale(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Box>
                        </Modal>

                        {/* ---------Give H2O modal--------------- */}

                        <Modal
                          open={opens}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <Typography
                              sx={{ mb: 2 }}
                              id="modal-modal-title"
                              variant="h2"
                              component="h2"
                              textAlign={'center'}
                            >
                              Water The Apple Tree
                            </Typography>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <input
                                type="number"
                                placeholder="Enter value"
                                style={{
                                  border: '1px solid #93FB42',
                                  borderRadius: '5px',
                                  padding: 10,
                                  width: 170,
                                  background: '#fff'
                                }}
                                onChange={(e: any) => {
                                  setgivewatervalid(false);
                                  setGiveH20Input(e.target.value);
                                }}
                              />
                              <span
                                style={{
                                  paddingLeft: '10px',
                                  fontWeight: 'bold'
                                }}
                              >
                                Water
                              </span>
                            </Box>
                            {handleH20InputErr && (
                              <div
                                style={{
                                  marginLeft: '100px',
                                  marginTop: '10px',
                                  color: 'red'
                                }}
                              >
                                Required field.
                              </div>
                            )}
                            {givewatervalid && (
                              <div
                                style={{
                                  marginLeft: '90px',
                                  marginTop: '10px',
                                  color: 'red'
                                }}
                              >
                                Water should be greater than 0.
                              </div>
                            )}

                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 15
                              }}
                            >
                              <Button
                                sx={{ mt: 4, p: 2, mr: 2 }}
                                variant="contained"
                                onClick={call}
                                disabled={isH2OLoading}
                              >
                                {isH2OLoading ? (
                                  <Box sx={{ display: 'flex' }}>
                                    <CircularProgress
                                      size="1.5rem"
                                      color="inherit"
                                    />
                                  </Box>
                                ) : (
                                  'Proceed'
                                )}
                              </Button>
                              <Button
                                sx={{
                                  mt: 4,
                                  p: 2,
                                  backgroundColor: '#c21118'
                                }}
                                variant="contained"
                                onClick={() => {
                                  setGiveH20Input(0);
                                  sethandleH20InputErr(false);
                                  setgivewatervalid(false);
                                  setH2OLoading(false);
                                  setOpens(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Box>
                        </Modal>

                        <Button
                          onClick={stakingFunction}
                          className="btn-green next-level-btn"
                          variant="contained"
                          color="success"
                          sx={{
                            borderRadius: '5px 5px 10px 5px'
                          }}
                          disabled={isStakeLoading}
                        >
                          {isStakeLoading ? (
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress size="1.5rem" color="inherit" />
                            </Box>
                          ) : stakeNft ? (
                            'Unstake'
                          ) : (
                            'Stake'
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Grid>

              <NFTAttributes
                dataLoader={dataLoader}
                NFTDetailsByToken={NFTDetailsByToken}
                h2OAmount={h2OAmount}
                nftCurrentlevel={nftCurrentlevel}
                nftHeight={nftHeight}
              />
            </Grid>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default NFTDetails;
