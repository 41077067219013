import React from 'react';
import { Navigate, Route, Outlet, useLocation } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const userSignIn = localStorage.getItem('USER_ID');
  const Isloggedin = JSON.parse(localStorage.getItem('IsloggedIn'));
  // console.log(Isloggedin);
  // console.log('USERSIGNINNNN', userSignIn);
  const location = useLocation();
  // console.log('CHILDEr', children);

  return Isloggedin ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
export default ProtectedRoute;
