import { useContext } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import "./Sidebar.css"
import GridViewIcon from '@mui/icons-material/GridView';
import RedeemIcon from '@mui/icons-material/Redeem';
import GroupIcon from '@mui/icons-material/Group';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import Slide from 'react-reveal/Slide';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;          
          color: #000000;
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          transition: .5s ease-out;
          background: linear-gradient(to left, #fa807200 50%, white 50%) right;
          background-size: 200%;
          transition: .5s ease-out

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.black[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }
          img{
            display: none;
          }
          &.active{
            img{
              display: flex;
            }
          }
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: #27aa58;
            background-position: left;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                'transform',
                'opacity'
              ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper bgcolor={"blue5"} className='warapper'>
        <SubMenuWrapper>
          <List component="div">
            {/* <Slide left cascade duration={800}> */}
            <Slide left cascade duration={500}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/main"
                  startIcon={<GridViewIcon />}
                >
                  Dashboard
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                </Button>
              
            </ListItem>
            </Slide>
            <Slide left cascade duration={600}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/eco-team"
                  startIcon={< GroupIcon/>}
                >
                  Eco Team
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                </Button>
              
            </ListItem>
            </Slide>
            <Slide left cascade duration={700}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/nfts"
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  My NFT's
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                </Button>
              
            </ListItem>
            </Slide>
            <Slide left cascade duration={800}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/mybonus"
                  startIcon={<RedeemIcon />}
                >
                  My Bonus

                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />               </Button>

                           
            </ListItem>
            </Slide>
            <Slide left cascade duration={900}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/myposts"
                  startIcon={<PostAddIcon />}>
                  My Posts

                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />               </Button>
                          
            </ListItem>
            </Slide> 
            {/* <Slide left cascade duration={1000}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/Timelineposts"
                  startIcon={<ViewTimelineIcon />}
                >
                  Timeline Posts

                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />               </Button>
              
             </ListItem>
             </Slide> */}
             <Slide left cascade duration={1100}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/transactions"
                  startIcon={<CurrencyExchangeIcon />}
                >
                  Transactions

                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />               </Button>
              
            </ListItem>
            </Slide>
            <Slide left cascade duration={1200}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/withdraw"
                  startIcon={<BallotTwoToneIcon />}
                >
                  Withdraw Matic
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                </Button>
              
            </ListItem>
            </Slide>
            <Slide left cascade duration={1300}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/marketplace"
                  startIcon={<StorefrontIcon />}
                >
                  Marketplace
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                </Button>
              
            </ListItem>
            </Slide>
            <Slide left cascade duration={1400}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/farm"
                  startIcon={<AgricultureIcon />} >
                  Farm
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                </Button>
                
            </ListItem>
            </Slide>
            <Slide left cascade duration={1500}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/leaderboard"
                  startIcon={<LeaderboardIcon />}
                >
                  Leaderboard
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                </Button>
              
            </ListItem>
            </Slide>
            {/* <Slide left cascade duration={1600}>
            <ListItem component="div">
              
                <Button className='russoFont' style={{ fontWeight: '400', paddingRight: '0', paddingBottom: '5px', paddingTop: '5px' }}
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/support"
                  startIcon={<SupportAgentIcon />}
                >
                  Support
                  <img src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&token=c8928674-602c-4edf-9058-7941fa74fcdd" style={{ marginLeft: 'auto' }} />
                  
                </Button>
              
            </ListItem>
            </Slide> */}
            {/* </Slide> */}

          </List>
        </SubMenuWrapper>


      </MenuWrapper>

    </>
  );
}

export default SidebarMenu;
