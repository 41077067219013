import { Avatar } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const Header = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <>
      <div className="header-section">
        <Avatar
          style={{ width: '60px', height: '40px', paddingLeft: '10px' }}
          alt="CLW Logo"
          src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/logoPart1.png?alt=media&token=46175a58-c24c-4ce9-a269-3db92532704a"
        />
        <Avatar
          style={{ width: '125px', borderRadius: '0', height: '40px' }}
          alt="CLW Logo New"
          src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/logoPart2-1.png?alt=media&token=e46733e7-2be2-41a3-bb3e-2e46ced49cca"
        />

        <div className="languages">
          <select
            className="russoFont"
            name="languages"
            id="languages"
            onChange={handleChangeLanguage}
            defaultValue={i18n.language}
          >
            <option value="en">ENGLISH</option>
            <option value="simplifiedchinese">SIMPLIFIED CHINESE</option>
            <option value="traditionalchinese">TRADITIONAL CHINESE</option>
            <option value="vietnamese">VIETNAMESE</option>
            <option value="bahasaindonesia">BAHASA INDONESIA</option>
            <option value="bm">BAHASA MALAYSIA</option>
          </select>
        </div>

        <p
          className="lasttext"
          onClick={() => {
            navigate('/login');
          }}
        >
            {t('login')}
          {/* Login */}
        </p>
        <span
          className="signuptext"
          onClick={() => {
            navigate('/register');
          }}
        >
             {t('signup')}
          {/* Signup */}
        </span>
      </div>
    </>
  );
};

export default Header;
