import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const Traits = ({ trait }) => {
  return (
    <>
      <Grid
        sx={{ m:0}}
        item
        xs={6}
        sm={4}
        md={4}
        textAlign={{ sm: 'center' }}
      >
        <Box sx={{ 
            backgroundColor: 'rgba(161, 219, 150, 0.5)' ,
            borderColor: '#27AA58',
            borderWidth:1,
            borderStyle: 'solid',
            padding: 1,
            marginX:1,
          }}>
          <Typography
            className="heading-text"
            variant="h4"
            component="h4"
            sx={{
              fontSize: 15,
              color: '#4e704e',
              fontWeight: 400
            }}
          >
            {trait?.trait_type}
          </Typography>
          <Typography
            variant="body1"
            component="div"
            className="trait-data"
            sx={{ fontSize: 18, fontWeight: 400, color: '#000',}}
          >
            {trait?.value}
          </Typography>
        </Box>
        <hr className="dashed" />
      </Grid>
    </>
  );
};

export default Traits;
