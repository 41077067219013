import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import nftlevel from '../nft.json';
import Traits from './Traits';

const NFTAttributes = ({
  dataLoader,
  NFTDetailsByToken,
  h2OAmount,
  nftCurrentlevel,
  nftHeight
}) => {

  const [expandedAccordian1, setExpandedAccordian1] = useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordian1(newExpanded ? panel : false);
    };  
  return (
    <>
      <Grid item md={7} className="ml-5">

        <Accordion 
          expanded={expandedAccordian1 === 'panel1'} onChange={handleChange('panel1')}
          // expanded={true}
          className="accordionBlock"
          sx={{ 
            background: 'transparent', 
            display: 'flex', 
            flexDirection: 'column',
            m:0,
            width: '100%'
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ 
              m:2,
              background: 'linear-gradient(to right, #27AA58 0%, rgba(161, 219, 150, 1) 100%)',
            }}
          >
            <Typography
              className="Info-text-value"
              variant="h5"
              component="h5"
              sx={{ fontSize: 20, fontWeight: 400, color: '#fff'}}
            >
              Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid 
              spacing={2}
              sx={{ 
                display: 'flex', 
                flexWrap: 'wrap'
              }}>
              <Grid
                sx={{ m:0}}
                item
                xs={6}
                sm={4}
                md={4}
                textAlign={{ sm: 'center' }}
              >
                <Box sx={{ 
                  backgroundColor: 'rgba(161, 219, 150, 0.5)' ,
                  borderColor: '#27AA58',
                  borderWidth:1,
                  borderStyle: 'solid',
                  padding: 1,
                  marginX:1,
                }}>
                  <Typography
                    className="heading-text"
                    variant="h4"
                    component="h4"
                    sx={{
                      fontSize: 15,
                      color: '#4e704e',
                      fontWeight: 400
                    }}
                  >
                    Height{' '}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="trait-data"
                    sx={{ fontSize: 18, fontWeight: 400 }}
                  >
                    {dataLoader ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 1
                        }}
                      >
                        <CircularProgress size="1.5rem" color="inherit" />
                      </Box>
                    ) : (
                      `${nftHeight ? nftHeight : '0'}  Meter`
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                sx={{ m:0}}
                item
                xs={6}
                sm={4}
                md={4}
                textAlign={{ sm: 'center' }}
              >
                <Box sx={{ 
                  backgroundColor: 'rgba(161, 219, 150, 0.5)' ,
                  borderColor: '#27AA58',
                  borderWidth:1,
                  borderStyle: 'solid',
                  padding: 1,
                  marginX:1,
                }}>
                  <Typography
                    className="heading-text"
                    variant="h4"
                    component="h4"
                    sx={{
                      fontSize: 15,
                      color: '#4e704e',
                      fontWeight: 400
                    }}
                  >
                    <span className='blinking-text'>Watered</span>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="trait-data"
                    sx={{ fontSize: 18, fontWeight: 400 }}
                  >
                    {dataLoader ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 1
                        }}
                      >
                        <CircularProgress size="1.5rem" color="inherit" />
                      </Box>
                    ) : (h2OAmount)?.toFixed(2) ? (
                      (h2OAmount)?.toFixed(2)
                    ) : (
                      0
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                sx={{ m:0}}
                item
                xs={6}
                sm={4}
                md={4}
                textAlign={{ sm: 'center' }}
              >
                <Box sx={{ 
                  backgroundColor: 'rgba(161, 219, 150, 0.5)' ,
                  borderColor: '#27AA58',
                  borderWidth:1,
                  borderStyle: 'solid',
                  padding: 1,
                  marginX:1,
                }}>
                  <Typography
                    className="heading-text"
                    variant="h4"
                    component="h4"
                    sx={{
                      fontSize: 15,
                      color: '#4e704e',
                      fontWeight: 400
                    }}
                  >
                    Score
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="trait-data"
                    sx={{ fontSize: 18, fontWeight: 400 }}
                  >
                    {dataLoader ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 1
                        }}
                      >
                        <CircularProgress size="1.5rem" color="inherit" />
                      </Box>
                    ) : (
                      NFTDetailsByToken?.land
                    )}
                  </Typography>
                </Box>
              </Grid>
              <hr className="dashed" />
              <Grid
               sx={{ m:0}}
                item
                xs={6}
                sm={4}
                md={4}
                textAlign={{ sm: 'center' }}
              >
                <Box sx={{ 
                  backgroundColor: 'rgba(161, 219, 150, 0.5)' ,
                  borderColor: '#27AA58',
                  borderWidth:1,
                  borderStyle: 'solid',
                  padding: 1,
                  marginX:1,
                }}>
                  <Typography
                    className="heading-text"
                    variant="h4"
                    component="h4"
                    sx={{
                      fontSize: 15,
                      color: '#4e704e',
                      fontWeight: 400
                    }}
                  >
                    Level
                  </Typography>

                  <Typography
                    variant="body1"
                    component="div"
                    className="trait-data"
                    sx={{ fontSize: 18, fontWeight: 400 }}
                  >
                    {dataLoader ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 1
                        }}
                      >
                        <CircularProgress size="1.5rem" color="inherit" />
                      </Box>
                    ) : nftCurrentlevel ? (
                      nftCurrentlevel
                    ) : (
                      '0'
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                sx={{ m:0}}
                item
                xs={6}
                sm={4}
                md={4}
                textAlign={{ sm: 'center' }}
              >
                <Box sx={{ 
                  backgroundColor: 'rgba(161, 219, 150, 0.5)' ,
                  borderColor: '#27AA58',
                  borderWidth:1,
                  borderStyle: 'solid',
                  padding: 1,
                  marginX:1,
                }}>
                  <Typography
                    className="heading-text"
                    variant="h4"
                    component="h4"
                    sx={{
                      fontSize: 15,
                      color: '#4e704e',
                      fontWeight: 400
                    }}
                  >
                    Next Level
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="trait-data"
                    sx={{ fontSize: 18, fontWeight: 400, color: '#000', }}
                  >
                    {dataLoader ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 1
                        }}
                      >
                        <CircularProgress size="1.5rem" color="inherit" />
                      </Box>
                    ) : nftlevel[nftCurrentlevel] ? (
                      nftlevel[nftCurrentlevel]
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size="1.5rem" color="inherit" />
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion 
          sx={{ background: 'transparent' }}
          className="accordionBlock"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ 
              m:2,
              background: 'linear-gradient(to right, #27AA58 0%, rgba(161, 219, 150, 1) 100%)', 
            }}
          >
            <Typography
              className="Info-text-value"
              variant="h5"
              component="h5"
              sx={{ fontSize: 20, fontWeight: 400, color:'#fff' }}
            >
              Attributes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid className="attri-bg-box">
              <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {NFTDetailsByToken?.traits?.map((trait) => (
                  <Traits key={trait?._id} trait={trait} />
                ))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

export default NFTAttributes;
