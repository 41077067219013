import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import { ForgotPassword } from './components/ForgotPassword/ForgotPassword';
import NFTS from 'src/content/applications/Users/NFTS';
import NFTDetails from 'src/content/applications/Users/NFTS-details';
import LeaderBoard from 'src/content/pages/Components/LeaderBoard';
import MyBonus from 'src/content/pages/Components/MyBonus';
import Support from 'src/content/pages/Components/Support';
import Timelineposts from 'src/content/pages/Components/Timelineposts';
import ResetPassword from './components/ResetPassword/ResetPassword';
import SignupConfirm from './components/SignupConfirmation/SignupConfirm';
import SentEmail from './components/ForgotPassword/SentEmail';
import ProtectedRoute from './Utils/ProtectedRoute';
import { PublicRoute } from './Utils/PublicRoute';
import InvalidToken from './components/ResetPassword/InvalidToken';


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
 
      <Component {...props} />
    </Suspense>
  );

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications
const MyEcoTeam = Loader(
  lazy(() => import('src/content/applications/MyEcoTeam'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/NFTS'))
);

const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Withdraw = Loader(
  lazy(() => import('src/content/pages/Components/Withdraw'))
);
const MyMarketPlace = Loader(
  lazy(() => import('src/content/pages/Components/Marketplace'))
);
const MyPosts = Loader(
  lazy(() => import('src/content/pages/Components/MyPosts'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/LeaderBoard')));

const Farm = Loader(
  lazy(() => import('src/content/pages/Components/Farm'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Support'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/MyBonus')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
// const ProtectedRoute = Loader(
//   lazy(() => import('src/Utils/ProtectedRoute'))
// );
const routes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: '/',
        element: <PublicRoute><Login /></PublicRoute>
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'tokenexpired',
        element: <InvalidToken/>
      },
      {
        path: 'login',
        element:  <PublicRoute><Login /></PublicRoute>
      },
      {
        path: 'login/:tokens',
        element:  <Login />
      },
      {
        path: 'register',
        element: <Register/>
      },
      {
        path: 'forgotpassword',
        element: <ForgotPassword/>
      },
      {
        path: 'user/resetpassword/:token',
        element: <ResetPassword/>
      },
      {
        path: 'signupconfirm',
        element:<SignupConfirm/>
      },
      {
        path: 'sentemail',
        element: <SentEmail/>
      },
      {
        path: 'Auth',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <ProtectedRoute> <SidebarLayout /></ProtectedRoute>,
    children: [
      // {
      //   path: '',
      //   element: <Navigate to="crypto" replace />
      // },
      {
        path: 'main',
        element: (<ProtectedRoute><Crypto /></ProtectedRoute>)
      },
      {
        path: 'eco-team',
        element:   (<ProtectedRoute><MyEcoTeam/></ProtectedRoute>)
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element:  (<ProtectedRoute><Transactions /></ProtectedRoute>) 
      },
      {
        path: 'nfts',
        element:  (<ProtectedRoute><NFTS /></ProtectedRoute>)  ,
      },
      {
        path: 'nfts-details/:contract/:identifier',
        element:  (<ProtectedRoute><NFTDetails /></ProtectedRoute>)
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: (<ProtectedRoute><UserProfile /></ProtectedRoute>)  
          },
          {
            path: 'settings',
            element:  (<ProtectedRoute><UserSettings /></ProtectedRoute>) 
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: 'withdraw',
        element: (<ProtectedRoute><Withdraw /></ProtectedRoute>)
      },
      {
        path: 'marketplace',
        element: (<ProtectedRoute><MyMarketPlace/></ProtectedRoute>)
      },
      {
        path: 'myposts',
        element:(<ProtectedRoute><MyPosts /></ProtectedRoute>) 
      },
      {
        path: 'leaderboard',
        element: (<ProtectedRoute><LeaderBoard/></ProtectedRoute>)
      },
      {
        path: 'Timelineposts',
        element:(<ProtectedRoute><Timelineposts /></ProtectedRoute>) 
      },
      {
        path: 'farm',
        element:(<ProtectedRoute> <Farm/></ProtectedRoute>)
      },
      {
        path: 'barn',
        element: (<ProtectedRoute><Cards /></ProtectedRoute>)
      },
      {
        path: 'mybonus',
        element:(<ProtectedRoute><MyBonus/></ProtectedRoute>) 
      },
      {
        path: 'support',
        element:(<ProtectedRoute><Support/></ProtectedRoute>) 
      }
    ]
  }
];

export default routes;
