import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import './Languages.css';

type Props = {};

const Languages = (props: Props) => {
  return (
    <>
      <div className='languages' style={{ background:'transparent', border: '1px solid #ffffff', borderRadius: '5px', color: '#ffffff', paddingRight:'10px'}}>
        <select className='russoFont' defaultValue="english" name="languages" id="languages" style={{  background:'transparent', border:'none',color: '#ffffff', width:'140px', fontSize: '11px'}}>
          <option value="english">ENGLISH</option>
          <option value="simplifiedchinese">SIMPLIFIED CHINESE</option>
          <option value="traditionalchinese">TRADITIONAL CHINESE</option>
          <option value="vietnamese">VIETNAMESE</option>
          <option value="bahasaindonesia">BAHASA INDONESIA</option>
          <option value="bahasamalaysia">BAHASA MALAYSIA</option>

        </select>
      </div>
    </>
  );
};

export default Languages;
