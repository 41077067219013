import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import './Wallet.css';
import { ConnectWallet, useMetamask, useAddress } from '@thirdweb-dev/react';
import axios from 'axios';
declare var window: any;

const Wallet = () => {
  const userId = localStorage.getItem('USER_ID');
  let userDetails = JSON.parse(localStorage.getItem('userdetails'));
  const userWallet = userDetails?.walletAddress;
  console.log('userWallet 1', userWallet);
  const [loading, setLoading] = useState(false);
  const [switchNErr, setswitchNErr] = useState(false);
  const address = useAddress();
  const connect = useMetamask();

  const addressCheck = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts'
        });
        const walletStatus = userDetails?.walletStatus;
        console.log('walletStatus', walletStatus);
        const accountW = accounts[0]?.toLocaleLowerCase();
        console.log('accountW', accountW);
        if (walletStatus) return;

        if (!walletStatus || userWallet == undefined) {
          try {
            axios
              .patch(
                `${process.env.REACT_APP_HOME_URL}/user/update/${userId}`,
                {
                  walletAddress: accountW,
                  walletStatus: true
                }
              )
              .then((res) => {
                console.log('accounts', accounts[0]);
                console.log(res);
                localStorage.setItem(
                  'userdetails',
                  JSON.stringify(res?.data?.user)
                );
              })
              .catch((err) => {});
          } catch (err) {
            console.log(err);
          }
        }
      }
    } catch (err) {
      console.log('----------------', err);
    }
  };

  console.log('userwallet 2', userWallet);
  console.log('address', address?.toLocaleLowerCase());

  useEffect(() => {
    if (!address) {
      addressCheck();
    } else {
      if (userWallet === address?.toLocaleLowerCase()) {
        console.log('same');
      }
    }
  }, [address]);

  const handleConnect = async () => {
    setLoading(true);
    try {
      await connect();
      // console.log('Connected successfully');
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  async function switchN() {
    const provider = window.ethereum;
    const polygonChainId = '0x89';
    if (!provider) {
      return;
    } else {
      try {
        const chainId = await provider.request({ method: 'eth_chainId' });
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: polygonChainId }]
        });
        if (!address) {
          handleConnect();
        }
      } catch (switchError) {
        setswitchNErr(true);
        if (switchError.code === 4902) {
          try {
            await provider
              .request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x89',
                    chainName: 'Polygon Mainnet',
                    rpcUrls: ['https://polygon-rpc.com'],
                    blockExplorerUrls: ['https://www.polygonscan.com'],
                    nativeCurrency: {
                      name: 'MATIC',
                      symbol: 'MATIC',
                      decimals: 18
                    }
                  }
                ]
              })
              .then(() => {
                console.log('Polygon Matic added successfully');
                // After adding the network, call handleConnect
                if (!address) {
                  handleConnect();
                }
              })
              .catch((error) => console.log('Error:', error.message));
          } catch (addError) {
            // handle "add" error
            console.log(addError);
          }
        }
      }
    }
  }

  const activities = () => {
    // console.log('insidee1');
    if ((typeof window.ethereum as any) === 'undefined') {
      // console.log('insidee2');
      setLoading(true);
      return;
    }
    // console.log('insidee3');

    if (!address) {
      switchN();
    } else {
      // console.log('insidee');
      switchN();
    }
  };

  return (
    <>
      <Tooltip title={!address ? 'Connect wallet' : 'Connected wallet'}>
        {/* <button style={{ background: 'transparent' }} className="wallet" onClick={checkIfWalletIsConnected}> */}
        <Button
          style={{ background: 'transparent' }}
          className="wallet"
          // onClick={() => (!address ? handleConnect() : switchN())}
          onClick={activities}
          disabled={loading}
        >
          {loading ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size="1.5rem" color="inherit" />
            </Box>
          ) : (
            <i
              className="fa-solid fa-wallet fa-2xl"
              style={{ color: '#ffffff' }}
            ></i>
          )}
        </Button>
      </Tooltip>
    </>
  );
};

export default Wallet;
