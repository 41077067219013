import { Helmet } from 'react-helmet-async';
import './nfts.css';
import MYNFTS from './MyNFTS';
import Footer from 'src/components/Footer';

const NFTS = () => {
  return (
    <>
      <Helmet>
        <title>NFT - CLW</title>
      </Helmet>
      <MYNFTS />
      <Footer />
    </>
  );
};

export default NFTS;
