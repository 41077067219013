import { Helmet } from 'react-helmet-async';
import { Container, CssBaseline, Avatar } from '@mui/material';
import Footer from 'src/components/Footer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import './mybonus.css';
import axios from 'axios';
import LoaderAnimation from 'src/components/LoaderAnimation/LoaderAnimation';

 const columns = [
    {
      field: 'username',
      headerName: 'User name',
      width: 250,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={params?.row?.user[0]?.image}
            style={{ width: '40px', height: '40px' }}
          />
          <span style={{ marginLeft: '20px' }}>
            {params?.row?.user[0]?.username}
          </span>
        </div>
      )
    },

    {
      field: 'level',
      headerName: 'Bonus Level',
      flex: 0.75,
      renderCell: (params) => (
        <div style={{ marginLeft: '20px' }}>{params?.value}</div>
      )
    },
    {
      field: 'percent',
      headerName: 'Bonus %',
      flex: 0.75,
      renderCell: (params) => (
        <div style={{ marginLeft: '20px' }}>{params?.value}</div>
      )
    },
    {
      field: 'bonusAmout',
      headerName: 'Bonus Amount',
      flex: 1,
      renderCell: (params) => (
        <div style={{ marginLeft: '20px' }}>{(params?.value).toFixed(4)}</div>
      )
    }
  ];


const MyBonus = () => {
  const [LoadingData, setLoadingData] = useState(false);
  const [userbonus, setUserBonus] = useState([]);
  const [totalBonus, setTotalBonus] = useState('');
  const userId = localStorage.getItem('USER_ID');

  const alluserbonus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/bonus/${userId}`
      );
      const data = response?.data?.data?.bonusAmout;
      const newdata = data.toFixed(4)
      setTotalBonus(newdata);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const bonusListing = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/bonus/getallbonus/${userId}`
      );
      const data = response?.data?.data;
      console.log(response, 'add');

      setUserBonus(data);
      setLoadingData(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    alluserbonus();
    bonusListing();
  }, []);

  return (
    <>
      <Helmet>
        <title>My Bonus - CLW</title>
      </Helmet>
      <div
        style={{
          backgroundImage: 'url(../static/images/tree-bg.png)',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh'
        }}
      >
        <Container>
          <CssBaseline />
          <div className="page-title">
            <span
              className="russoFont afterBorder"
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                fontSize: '26px',
                textTransform: 'capitalize',
                color: '#27AA58',
                margin: '0',
                marginBottom: '20px'
              }}
            >
              Total Bonus
              <Typography
                component={'span'}
                variant="inherit"
                className="total_in"
              >
                [{totalBonus ? totalBonus : '0'} <span>MATIC</span>]
              </Typography>
            </span>
          </div>

          <CssBaseline />
          <Box className="box1">
            <Box className="innerr_box">
              <div
                style={{
                  height: 'calc(100vh - 170px)',
                  width: '90%',
                  margin: 'auto',
                  backgroundColor: 'transparent',
                  borderRadius: '10px'
                }}
              >
                {LoadingData ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 400
                    }}
                  >
                    <LoaderAnimation />
                  </Box>
                ) : userbonus?.length === 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize:'24px',
                      fontWeight:'bold',
                    }}
                    className='noDataFound'
                  >
                    <p className='russoFont'>No Bonus Found</p>
                  </Box>
                ) : (
                  <DataGrid
                    rows={userbonus}
                    getRowId={(row) => row._id}
                    columns={columns}
                    className="mybonustable transation"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 }
                      }
                    }}
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterValues: [''],
                        },
                      },
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 }
                      }
                    }}
                  />
                )}
              </div>
            </Box>
          </Box>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default MyBonus;
