import React from 'react';
import {
  Box,
  Card,
  Typography,
  Container,
  Divider,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

const InvalidToken = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Status - 404</title>
      </Helmet>

      <div
        style={{
          backgroundImage: 'url(../static/images/tree-bg.png)',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          height: '100vh'
        }}
      >
        <Container>
          <Box
            sx={{
              backgroundColor: '#fff',
              border: '1px solid rgb(147, 251, 66)',
              borderRadius: '14px',
              height: '50vh',
              maxWidth: 800,
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              padding: '20px',
              transform: 'translateY(50%)'
            }}
          >
            <Typography sx={{
                fontSize: 18,
                color: '#27AA58',
                mb:2,
            }}>
                User doesn't exist with this token.
            </Typography>
            <Button
              onClick={() => navigate('/login')}
              variant="contained"
              color="primary"
              sx={{
                mt: 4
              }}
            >
              Back to homepage
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default InvalidToken;
