import * as React from "react"
import './LoaderAnimation.css';

const LoaderAnimation = () => (
    <div className="loaderWrapper">
        <div className="loaderContainer">
            <span className="loaderIcons"><img src="/static/images/earth.png" /></span>
            <span className="loaderIcons"><img src="/static/images/fire.png" /></span>
            <span className="loaderIcons"><img src="/static/images/wood.png" /></span>
            <span className="loaderIcons"><img src="/static/images/gold.png" /></span>
            <span className="loaderIcons"><img src="/static/images/water.png" /></span>
        </div>
    </div>
)

export default LoaderAnimation