import { Helmet } from 'react-helmet-async';
import { Container, Avatar } from '@mui/material';
import { useState, SyntheticEvent, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import LoaderAnimation from 'src/components/LoaderAnimation/LoaderAnimation';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function LeaderBoard() {
  const [LoadingData, setLoadingData] = useState(false);
  const [LoadingTallestTreeData, setLoadingTallestTreeData] = useState(false);
  const [TallestTreeData, setTallestTreeData] = useState([]);
  const [TopScoreDataWithSort, setTopScoreDataWithSort] = useState([]);
  const [MostPost, setMostPost] = useState([]);
  const [HeightestLand, setHeightestLand] = useState([]);
  const [StrongestEcoTeam, setStrongestEcoTeam] = useState([]);
  const [RarityRankData, setRarityRankData] = useState([]);

  const RarityData = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/nft/nftWithRankSorted`
      );
      const data = response?.data?.data;
      // console.log(response);
      setRarityRankData(data);
      setLoadingData(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const StrongestEcoTeamData = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/bonus/getBonusSorted`
      );
      const data = response?.data?.data;
      setStrongestEcoTeam(data);
      // console.log(response)
      setLoadingData(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const HighestLand = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/nft/nftWithPriceSorted`
      );
      const data = response?.data?.data;
      console.log('response', response);
      setHeightestLand(data);
      setLoadingData(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const MostPosts = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/most/post`
      );
      const data = response?.data?.data;
      // console.log(response)
      setMostPost(data);
      setLoadingData(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const NftLandSorted = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/nft/nftWithLandSorted`
      );
      const data = response?.data?.data;
      setTopScoreDataWithSort(data);
      setLoadingData(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const TallestTree = async () => {
    try {
      setLoadingTallestTreeData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/nft/usersWithNftHeight`
      );
      const data = response?.data?.data;
      setTallestTreeData(data);
      setLoadingTallestTreeData(false);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  useEffect(() => {
    RarityData();
    StrongestEcoTeamData();
    HighestLand();
    MostPosts();
    NftLandSorted();
    TallestTree();
  }, []);

  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet>
        <title>Leaderboard - CLW</title>
      </Helmet>
      <div
        style={{
          backgroundImage: 'url(../static/images/tree-bg.png)',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh'
        }}
      >
        <Container>
          <div className="page-title">
            <p
              className="russoFont afterBorder"
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                fontSize: '26px',
                textTransform: 'capitalize',
                color: '#27AA58',
                margin: 0,
                marginBottom: '20px'
              }}
            >
              Leaderboard
            </p>
          </div>

          <div
            style={{
              width: '90%',
              margin: '0 auto',
              paddingBottom: '50px'
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  aria-label="lab API tabs example"
                  className="customTabsMain"
                  variant="fullWidth"
                >
                  <Tab
                    label="Tallest Tree"
                    {...a11yProps(0)}
                    className="customeTab"
                  />
                  <Tab
                    label="Strongest Eco-team"
                    {...a11yProps(1)}
                    className="customeTab"
                  />
                  <Tab
                    label="Top Score"
                    {...a11yProps(2)}
                    className="customeTab"
                  />
                  <Tab
                    label="Highest Value Land"
                    {...a11yProps(3)}
                    className="customeTab"
                  />
                  <Tab
                    label="Most Posts"
                    {...a11yProps(4)}
                    className="customeTab"
                  />
                  <Tab
                    label="Top Rarity Rank"
                    {...a11yProps(5)}
                    className="customeTab"
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Box
                  className="w-100"
                  sx={{
                    mb: 2,
                    display: 'flex'
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Rank according to the tree height.
                  </Typography>
                </Box>

                {LoadingTallestTreeData ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 400
                    }}
                  >
                    <LoaderAnimation />
                  </Box>
                ) : TallestTreeData?.length > 0 ? (
                  <Box
                    className="bg w-100"
                    sx={{ p: 0, flexDirection: 'column' }}
                  >
                    <TableContainer
                      sx={{ maxHeight: 400 }}
                      component={Paper}
                      className="scrollbar-thin"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky"
                        className="table-data-list"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#EDFFE5' }}>
                            <TableCell style={{ width: '25%' }}>Rank</TableCell>
                            <TableCell style={{ width: '50%' }}>
                              Player Name
                            </TableCell>
                            <TableCell style={{ width: '25%' }}>
                              Height
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {TallestTreeData.map((user, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell>
                                {' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Avatar
                                    src={user?.user[0]?.image}
                                    sx={{
                                      marginRight: '20px',
                                      width: '40px',
                                      height: '40px'
                                    }}
                                  />
                                  {user?.user[0]?.username}
                                </div>
                              </TableCell>
                              <TableCell>{user?.height} Meter</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center', fontWeight: 400 }}>
                    <h4>No data found!</h4>
                  </div>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Box
                  className="w-100"
                  sx={{
                    mb: 2,
                    display: 'flex'
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Rank accordingly to the team activity which include : <br />{' '}
                    a) Number of bonus earned, b) Number of postings, and c)
                    Number of members in a team.
                  </Typography>
                </Box>
                {StrongestEcoTeam.length > 0 ? (
                  <Box
                    className="bg w-100"
                    sx={{ p: 0, flexDirection: 'column' }}
                  >
                    <TableContainer
                      sx={{ maxHeight: 400 }}
                      component={Paper}
                      className="scrollbar-thin"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky"
                        className="table-data-list"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#EDFFE5' }}>
                            <TableCell> Rank</TableCell>
                            <TableCell>Team Name</TableCell>
                            <TableCell>Bonus earned</TableCell>
                            <TableCell>No. Of Members</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {StrongestEcoTeam.map((data, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              {/* <TableCell>{data?.userId?.username}</TableCell> */}
                              <TableCell>
                                {' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Avatar
                                    src={data?.user[0]?.image}
                                    sx={{
                                      marginRight: '20px',
                                      width: '40px',
                                      height: '40px'
                                    }}
                                  />
                                  {data?.user[0]?.username}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div>
                                  {' '}
                                  {parseFloat(data?.bonusAmout.toFixed(5))}{' '}
                                  MATIC
                                </div>
                              </TableCell>
                              <TableCell>
                                <div>
                                  {data?.user[0]?.referalData[0]?.totalCount}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {LoadingData && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 400
                        }}
                      >
                        <LoaderAnimation />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center', fontWeight: 400 }}>
                    <h4>No data found!</h4>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box
                  className="w-100"
                  sx={{
                    mb: 2,
                    display: 'flex'
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Ranking according to highest Land NFT traits.
                  </Typography>
                </Box>
                {TopScoreDataWithSort.length > 0 ? (
                  <Box
                    className="bg w-100"
                    sx={{ p: 0, flexDirection: 'column' }}
                  >
                    <TableContainer
                      sx={{ maxHeight: 400 }}
                      component={Paper}
                      className="scrollbar-thin"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky"
                        className="table-data-list"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#EDFFE5' }}>
                            <TableCell sx={{ width: '10%' }}>Rank</TableCell>
                            <TableCell sx={{ width: '25%' }}>CLW</TableCell>
                            <TableCell sx={{ width: '40%' }}>
                              User Name
                            </TableCell>
                            <TableCell sx={{ width: '25%' }}>
                              Score{' '}
                              <span
                                style={{ fontSize: '12px', display: 'block' }}
                              >
                                (Land traits land NFTs)
                              </span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {TopScoreDataWithSort.map((data, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell>{data?.name}</TableCell>
                              {/* <TableCell>{data?.user[0]?.username}</TableCell>   */}
                              <TableCell>
                                {' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Avatar
                                    src={data?.user[0]?.image}
                                    sx={{
                                      marginRight: '20px',
                                      width: '40px',
                                      height: '40px'
                                    }}
                                  />
                                  {data?.user[0]?.username}
                                </div>
                              </TableCell>
                              <TableCell>{data?.land}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {LoadingData && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 400
                        }}
                      >
                        <LoaderAnimation />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center', fontWeight: 400 }}>
                    <h4>No data found!</h4>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Box
                  className="w-100"
                  sx={{
                    mb: 2,
                    display: 'flex'
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Ranking of land producing most CLW.
                  </Typography>
                </Box>
                {HeightestLand.length > 0 ? (
                  <Box
                    className="bg w-100"
                    sx={{ p: 0, flexDirection: 'column' }}
                  >
                    <TableContainer
                      sx={{ maxHeight: 400 }}
                      component={Paper}
                      className="scrollbar-thin"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky"
                        className="table-data-list"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#EDFFE5' }}>
                            <TableCell>Rank</TableCell>
                            <TableCell>CLW#</TableCell>
                            <TableCell>CLW Generated</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {HeightestLand.map((data, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell>{data?.name}</TableCell>
                              <TableCell>{data?.price} MATIC</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {LoadingData && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 400
                        }}
                      >
                        <LoaderAnimation />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center', fontWeight: 400 }}>
                    <h4>No data found!</h4>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Box
                  className="w-100"
                  sx={{
                    mb: 2,
                    display: 'flex'
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Rank according to Most Posts by the user.
                  </Typography>
                </Box>
                {MostPost?.length > 0 ? (
                  <Box
                    className="bg w-100"
                    sx={{ p: 0, flexDirection: 'column' }}
                  >
                    <TableContainer
                      sx={{ maxHeight: 400 }}
                      component={Paper}
                      className="scrollbar-thin"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky"
                        className="table-data-list"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#EDFFE5' }}>
                            <TableCell>Rank</TableCell>
                            <TableCell>Player Name</TableCell>
                            <TableCell>No. of Posts</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {MostPost.map((post, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              {/* <TableCell>{post?.user[0]?.username}</TableCell>    */}
                              <TableCell>
                                {' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Avatar
                                    src={post?.user[0]?.image}
                                    sx={{
                                      marginRight: '20px',
                                      width: '40px',
                                      height: '40px'
                                    }}
                                  />
                                  {post?.user[0]?.username}
                                </div>
                              </TableCell>
                              <TableCell>{post?.count}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {LoadingData && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 400
                        }}
                      >
                        <LoaderAnimation />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center', fontWeight: 400 }}>
                    <h4>No data found!</h4>
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Box
                  className="w-100"
                  sx={{
                    mb: 2,
                    display: 'flex'
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    The total Rarity score for an NFT is the sum of the rarity
                    score of all of its trait values.
                  </Typography>
                </Box>
                {RarityRankData.length > 0 ? (
                  <Box
                    className="bg w-100"
                    sx={{ p: 0, flexDirection: 'column' }}
                  >
                    <TableContainer
                      sx={{ maxHeight: 400 }}
                      component={Paper}
                      className="scrollbar-thin"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky"
                        className="table-data-list"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#EDFFE5' }}>
                            <TableCell>Rank</TableCell>
                            <TableCell>Player Name</TableCell>
                            <TableCell>Rarity Rank</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {RarityRankData.map((data, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              {/* <TableCell>{data?.user[0]?.username}</TableCell>    */}
                              <TableCell>
                                {' '}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Avatar
                                    src={data?.user[0]?.image}
                                    sx={{
                                      marginRight: '20px',
                                      width: '40px',
                                      height: '40px'
                                    }}
                                  />
                                  {data?.user[0]?.username}
                                </div>
                              </TableCell>
                              <TableCell>{data?.rank}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {LoadingData && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 400
                        }}
                      >
                        <LoaderAnimation />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 400,
                      marginTop: '40px'
                    }}
                  >
                    <h4>No data found!</h4>
                  </div>
                )}
              </TabPanel>
            </Box>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default LeaderBoard;
