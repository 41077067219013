import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Input, Popover, Radio, Modal, message } from 'antd';
import './Support.css';
import { Container, Button, Grid, Box, Link } from '@mui/material';
import Footer from 'src/components/Footer';
import './Support.css';
import axios from 'axios';

const Support = () => {
  const userdetail = JSON.parse(localStorage.getItem('userdetails'));
  const email = userdetail?.email;
  const username = userdetail?.username;
  const [data, setData] = useState({
    contactName: username,
    Email: email,
    subject: '',
    message: ''
  });

  const [subjectErr, setSubjectErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);

  useEffect(() => {
    if (data?.subject) {
      setSubjectErr(false);
    }
    if (data?.message) {
      setMessageErr(false);
    }
  }, [data?.subject, data?.message]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (data?.subject === '') {
        setSubjectErr(true);
        return;
      }
      if (data?.message === '') {
        setMessageErr(true);
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_HOME_URL}/contact/api`,
        data
      );
      toast.success(
        'Your query has been received and will be processed shortly.',
        {
          position: 'bottom-right',
          theme: 'dark'
        }
      );
      setData((prevState) => ({
        ...prevState,
        subject: '',
        message: ''
      }));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Support - CLW</title>
      </Helmet>

      <ToastContainer
        position="bottom-right"
        limit={1}
        autoClose={3000}
        theme='dark'
      />

      <div
        style={{
          backgroundImage: 'url(../static/images/tree-bg.png)',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh'
        }}
      >
        <Container>
          <div className="page-title">
            <span
              className="russoFont afterBorder"
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                fontSize: '26px',
                textTransform: 'capitalize',
                color: '#27AA58',
                margin: '0',
                marginBottom: '20px'
              }}
            >
              Contact Us
            </span>
          </div>

          <div
            style={{
              width: `calc(100% - 62px)`,
              margin: '20px auto',
              paddingBottom: '50px',
              backgroundColor: 'transparent',
              borderRadius: '10px'
            }}
          >
            <form onSubmit={onSubmit}>
              <Grid container spacing={0}>
                <Grid item xs={6} sx={{ m: 0, p: 0 }}>
                  <Box className="emailWrapper">
                    <Box className="inner">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        onChange={handleChange}
                        name="contactName"
                        value={username}
                        disabled
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sx={{ m: 0, p: 0 }}>
                  <Box className="emailWrapper">
                    <Box className="inner">
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        onChange={handleChange}
                        name="Email"
                        value={email}
                        disabled
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} sx={{ p: 0 }}>
                  <Box className="emailWrapper">
                    <Box className="inner">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        onChange={handleChange}
                        name="subject"
                        value={data.subject}
                        placeholder="Enter Subject"
                      />
                      {subjectErr && (
                        <div className="invalid-data">
                          This is a required field.
                        </div>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ p: 0 }}>
                  <Box className="emailWrapper">
                    <Box className="inner">
                      <textarea
                        className="form-control"
                        id="message"
                        placeholder="Type Something..."
                        name="message"
                        value={data.message}
                        onChange={handleChange}
                      />
                      {messageErr && (
                        <div className="invalid-data">
                          This is a required field.
                        </div>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <div
                className="inner"
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    mt: 2,
                    p: 4
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    minWidth: 150,
                    color: '#ffffff',
                    border: '1px solid  rgb(147, 251, 66)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px'
                  }}
                >
                  Submit
                </Button>
              </div>
            </form>

            <Box
              sx={{
                p: 2,
                mt: 2
              }}
              style={{
                width: `calc(100% - 40px)`,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid  rgb(147, 251, 66)',
                backgroundColor: '#edffe5',
                borderRadius: '10px'
              }}
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                For any questions and enquiries, head on over to our Discord
                channel and we will assist you!
              </p>

              <p style={{ textAlign: 'center', fontSize: '16px' }}>
                Click the link below to join our community.
              </p>

              <Link
                href="https://discord.com/invite/BEw9AyVNJQ"
                rel="noreferrer"
                target="_blank"
              >
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="primary"
                  style={{
                    color: '#ffffff',
                    border: '1px solid  rgb(147, 251, 66)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px'
                  }}
                >
                  <i
                    className="fa-brands fa-discord"
                    style={{ marginRight: '10px' }}
                  />
                  Join the Discord
                </Button>
              </Link>
            </Box>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Support;
