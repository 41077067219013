import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[2]};
    padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.secondary.main};
    font-size: 18px;
    display: block;
`
);

// const UserBoxDescription = styled(Typography)(
//   ({ theme }) => `
//         color: ${lighten(theme.palette.secondary.main, 0.5)}`
// );

function HeaderUserbox() {
  const navigate = useNavigate();
  const [ProfilePic, setProfilePic] = useState('');
  const userdetail = JSON.parse(localStorage.getItem('userdetails'));
  const profilep = userdetail?.image;
  const username = userdetail?.username;
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setProfilePic(profilep);
  }, [userdetail]);

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
    navigate('/login')
  };

  return (
    <>
      <UserBoxButton
        color="secondary"
        ref={ref}
        onClick={() => setOpen(true)}
        className="menuSideUser"
      >
        <Avatar variant="rounded" alt={username} src={ProfilePic} />
        <Hidden mdDown>
          <UserBoxText className='userNameBox'>
            <UserBoxLabel variant="body1" style={{ color: '#fff' }} className='userNameLogin'>
              {username}
            </UserBoxLabel>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <PlayArrowIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className="popoverMenu"
      >
        {/* <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={username} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{username}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox> */}

        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav" className="profileMenu">
          <ListItem
            button
            to="/management/profile/settings"
            component={NavLink}
            onClick={() => setOpen(false)}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItem>

          <ListItem
            button
            to="/components/support"
            component={NavLink}
            onClick={() => setOpen(false)}
          >
            <SupportAgentIcon fontSize="small" />
            <ListItemText primary="CUSTOMER SUPPORT" />
          </ListItem>

          <ListItem
            button
            to="/components/support"
            component={NavLink}
            onClick={logoutHandler}
          >
            <LockOpenTwoToneIcon fontSize="small" />
            <ListItemText primary="SIGN OUT" />
          </ListItem>
        </List>
        {/* <Box sx={{ m: 1 }}>
          <Button onClick={logoutHandler} color="primary" fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            SIGN OUT
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

export default HeaderUserbox;
